/* https://dev.to/paulriviera/add-azure-application-insights-to-a-static-nextjs-app-921
 */

import { AppInsightsContext, withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../gtm';

const AzureAppInsights = ({ children }: { children: React.ReactNode }) => (
  <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
);

const WithAppInsightsTracking = withAITracking(reactPlugin, AzureAppInsights);

export { WithAppInsightsTracking as ApplicationInsights };
