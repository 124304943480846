import { Box, Grid } from '@ingeniorforeningen/figurine-core';
import { PageTop, RelatedContent } from '@ingeniorforeningen/figurine-cms';
import TableOfContents from '@/components/tableOfContents/TableOfContentsComponent';
import { LayoutProps } from '../types';
import styles from '../Layouts.module.css';

export const InfoPageLayout = ({ children, properties, pageType }: LayoutProps) => {
  const hasFloater = Boolean(properties?.showTableOfContents);
  const hasRelatedContent =
    properties?.relatedContentCards && properties?.relatedContentCards?.length > 0;

  return (
    <>
      <PageTop data={{ properties, contentType: pageType }} mt={hasFloater ? '4em' : undefined} />

      <Box className={styles.content} data-with-floater={hasFloater}>
        <Grid container m={0} p={0}>
          {children}
        </Grid>
        {hasFloater && (
          <Box mt="xl">
            <Box style={{ position: 'sticky', top: '5em' }}>
              <TableOfContents markupItems={properties?.components?.items || []} />
            </Box>
          </Box>
        )}
      </Box>

      {hasRelatedContent && (
        <RelatedContent data={properties?.relatedContentCards?.[0]} mt="xl" mb="xl" />
      )}
    </>
  );
};

export default InfoPageLayout;
