import axios, { AxiosError } from 'axios';
import https from 'https';
import { DeliveryApiClientWrapper } from '@ingeniorforeningen/umbraco-client';
import tracker from '../gtm';

const httpsAgent = new https.Agent({ rejectUnauthorized: false });
const axiosInstance = axios.create({ httpsAgent });

export const deliveryClient = new DeliveryApiClientWrapper(
  process.env.NEXT_PUBLIC_UMBRACO_API_URL,
  axiosInstance,
);

export function getUmbracoURL(path = '') {
  return `${process.env.NEXT_PUBLIC_UMBRACO_API_URL}${path}`;
}

export async function fetchAPI(path: string) {
  // Build request URL
  const requestUrl = `${getUmbracoURL(`${path}`)}`;

  // Trigger API call
  const startTime = performance.now();

  return axios
    .get(requestUrl)
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      const endTime = performance.now();
      const totalTime = endTime - startTime;
      let message = '';
      if (error.response) {
        message = `Request made and server responded with error - ${totalTime} milliseconds`;
      } else if (error.request) {
        message = `The request was made but no response was received - ${totalTime} milliseconds`;
      } else {
        message = `Something happened in setting up the request that triggered an Error - ${totalTime} milliseconds`;
      }

      tracker.monitor?.exception({
        message: error.message,
        severityLevel: 3,
        properties: { message },
      });
      if (error.response && error.response.status === 404) {
        return null;
      }
      throw error;
    });
}
