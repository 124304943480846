import {
  AppShell,
  Breadcrumbs,
  TBreadcrumbsProps,
  TEnv,
  TLanguageSelectorProps,
} from '@ingeniorforeningen/figurine-core';
import {
  IApiContentResponseModel,
  IApiMediaWithCropsResponseModel,
} from '@ingeniorforeningen/umbraco-client';
import Head from 'next/head';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { deliveryApiHeaderParser, deliveryApiFooterParser } from '@ingeniorforeningen/figurine-cms';
import PreviewControls from '@/components/PreviewControls/PreviewControls';
import { calculateSoMeImageUrl } from '@/lib/apiHelpers';
import { guidToEditLink } from '@/lib/utilities';
import { resolveLayout } from './templates';
import styles from './Layouts.module.css';

type Props = {
  children: React.ReactNode;
  image?: IApiMediaWithCropsResponseModel;
  page?: IApiContentResponseModel & { image?: IApiMediaWithCropsResponseModel };
  settings: IApiContentResponseModel;
  breadcrumbs?: TBreadcrumbsProps['items'];
};

const Layout = ({ children, page, settings, breadcrumbs }: Props) => {
  const { isPreview, locale, query } = useRouter();
  const { id, properties, image, contentType } = page || {};

  const PageLayout = resolveLayout(contentType || 'default');


  return (
    <>
      <Head>
        <meta data-guid={id} />
        <title>{properties?.metaTitle || properties?.header}</title>
        <meta name="description" content={properties?.metaDescription} />
        <meta property="og:title" content={properties?.socialMediaTitle || properties?.metaTitle} />
        <meta
          property="og:description"
          content={properties?.socialMediaTeaser || properties?.metaDescription}
        />
        <meta
          property="og:image"
          content={page && calculateSoMeImageUrl(page.properties?.socialMediaImage)}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {properties?.excludeFromSearchEngines && <meta name="robots" content="noindex" />}
        {id && <meta data-editlink={guidToEditLink(id, locale || 'da')} />}
        <meta data-pagetype={contentType as string} />
      </Head>

      <AppShell
        theme="insurance"
        polymorphic={{
          link: NextLink,
          image: NextImage,
        }}
        language={locale as 'da' | 'en'}
        environmentVariables={{ env: process.env.NEXT_PUBLIC_ENV as TEnv }}
        footerData={settings && deliveryApiFooterParser(settings)}
        headerData={
          settings && {
            ...deliveryApiHeaderParser(settings, query.slug?.[query.slug.length - 1].toLowerCase()),
            selectedSite: 'IDA Forsikring',
            showUserLogin:false,
            languages: [
              {
                code: 'da',
                href: page?.cultures?.da?.path,
                target: '_self',
                label: 'Dansk',
              },
              {
                code: 'en',
                href: page?.cultures?.en?.path,
                target: '_self',
                label: 'English',
              },
            ] as TLanguageSelectorProps['items'],
            logo: {
              href: '/',
              icon: 'IdaForsikringLogo',
            },
          }
        }
      >
        <div>
          {isPreview && (
            <>
              <div className={styles.preview} />
              <PreviewControls />
            </>
          )}

          {breadcrumbs && breadcrumbs.length > 0 && (
            <Breadcrumbs items={breadcrumbs} mt="lg" mb="lg" variant="dark"/>
          )}

          <PageLayout properties={properties} image={image} pageType={page?.contentType}>
            {children}
          </PageLayout>
        </div>
      </AppShell>
    </>
  );
};

export default Layout;
