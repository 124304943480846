export const removeTrailingSlash = (str: string): string => {
  if (str === '/') {
    return str;
  }

  if (str.endsWith('/')) {
    return str.slice(0, -1);
  }

  return str;
};

export const nextPathToUrl = (slug: string[]): string => {
  let relativePath = '/';
  if (slug) {
    // ['bilforsikring', 'campingvogn'] -> '/bilforsikring/campingvogn/' (syntax of relativeurl)
    relativePath += `${[...slug].join('/')}/`;
  }
  return relativePath;
};

export const urlToNextPath = (url: string, locale: string): string[] =>
  url?.split('/').filter((e) => e && e !== locale) || '/';

export const guidToEditLink = (node: string, locale: string) => {
  return `${process.env.NEXT_PUBLIC_UMBRACO_BACK_OFFICE_URL}/umbraco#/content/content/edit/${node}?cculture=${locale}&mculture=${locale}`;
};
