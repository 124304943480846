import { AppProps, NextWebVitalsMetric } from 'next/app';
import { useEffect } from 'react';
import { ApplicationInsights } from '../appInsights';
import '@ingeniorforeningen/figurine-core/baseline.css';
import './App.css';
import tracker from '../../gtm';

export function reportWebVitals(metric: NextWebVitalsMetric) {
  tracker.monitor?.metric({ name: metric.name, average: metric.value });
}

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    const pageName = pageProps?.data?.page?.properties?.metaTitle
      ? pageProps?.data?.page?.properties?.metaTitle
      : pageProps?.data?.page?.properties?.header;
    const nodeId = pageProps?.data?.page?.id;
    tracker.trackPageView(pageName, nodeId);
  }, [
    pageProps?.data?.page?.id,
    pageProps?.data?.page?.properties?.header,
    pageProps?.data?.page?.properties?.metaTitle,
  ]);

  return (
    <ApplicationInsights>
      <Component {...pageProps} />
    </ApplicationInsights>
  );
};

export default App;
